






























import { Component, Prop, Vue } from 'vue-property-decorator';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';
import Card from 'primevue/card';

import axios from 'axios';
import RootVue from '@/RootVue';

@Component({
    components: {
        InputText,
        Password,
        Button,
        Card,
    }
})
export default class LoginPage extends Vue {
    username: string = "";
    password: string = "";
    errorMessage = "";

    async login() {
        try {
            let response = await axios.post('login', {
                email: this.username,
                password: this.password
            });
            (this.$root as RootVue).user = response.data;

            this.errorMessage = '';
            this.$router.push('dashboard');
        }
        catch (error: any) {
            let status = error.response?.status;
            if (status == 400) {
                this.errorMessage = 'Incorrect format for email/password';
            }
            else if (status == 401) {
                this.errorMessage = 'Credentials not valid';
            }
            else {
                this.errorMessage = `Something went wrong ${status ? `[${status}]` : ''}`.trim();
            }
        }
    }
}
