import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import router from './router'
import App from './App.vue'
import { User } from './models/User';
import { Tenant } from './models/Tenant';
import axios from 'axios';
import { MessageTypes } from './models/MessageTypes';
import { Message } from './models/Message';
import { ChannelSettings } from './models/ChannelSettings';
import { ToastSeverity } from 'primevue/api';
import { AgentClaimData } from './models/AgentClaimData';

@Component({
    router: router,
    render: h => h(App)
})
export default class RootVue extends Vue {
    user: User|null = null;
    usersInTenant: User[] = [];
    agentClaimsData: AgentClaimData[] = [];

    tenant: Tenant|null = null;
    tenants: Tenant[] = [];

    messages: {[key: string]: Message[]} = {
        [MessageTypes.New]: [],
        [MessageTypes.Claimed]: [],
        [MessageTypes.Unclaimed]: []
    }

    channels: ChannelSettings[] = [];

    async refreshData(retryLogin = true) {
        await this.refreshLogin();

        try {
            this.agentClaimsData = await this.getAgentClaimsInTenant();
            this.usersInTenant = await this.getUsersInTenant();
            this.tenants = await this.getTenants();
            this.tenant = await this.getOwnTenant();
            this.channels = await this.getChannels();

            let messageList = await this.getMessages();
            let messageMap: any = this.messages;
            for (let key of Object.keys(messageMap)) {
                messageMap[key] = messageList.filter(x => x.messageType == key);
            }
        }
        catch (error: any) {
            let status = error.response?.status;
            console.log('Refresh failed', error, this);
            if (status == 401) {
                this.user = null;
                let success = await this.refreshLogin();
                if (success && retryLogin) {
                    this.refreshData(false);
                }
            }
        }
    }

    async saveTenantData(retryLogin = true, suppressToast=false): Promise<boolean> {
        await this.refreshLogin();

        try {
            await axios.patch('api/tenant', this.tenant);
            if (!suppressToast) {
                this.$toast.add({severity: ToastSeverity.SUCCESS, summary: 'Saved!', detail:'Update successful.', life: 3000});
            }
            return true;
        }
        catch (error: any) {
            let status = error.response?.status;
            console.log('Save tenant failed', error, this);
            if (status == 401) {
                this.user = null;
                let success = await this.refreshLogin();
                if (success && retryLogin) {
                    return this.saveTenantData(false, suppressToast);
                }
            }

            this.$toast.add({severity: ToastSeverity.ERROR, summary: 'Save failed', detail: `Server responded with [${status}]. Check the console for more information (F12)`, life: 3000});
            return false;
        }
    }

    async saveMessageData(retryLogin = true): Promise<boolean> {
        await this.refreshLogin();

        try {
            let messages = [...this.messages.New, ...this.messages.Claimed, ...this.messages.Unclaimed];
            await axios.patch('api/messages', messages);
            this.$toast.add({severity: ToastSeverity.SUCCESS, summary: 'Saved!', detail:'Update successful.', life: 3000});
            return true;
        }
        catch (error: any) {
            let status = error.response?.status;
            console.log('Save messages failed', error, this);
            if (status == 401) {
                this.user = null;
                let success = await this.refreshLogin();
                if (success && retryLogin) {
                    return this.saveMessageData(false);
                }
            }

            this.$toast.add({severity: ToastSeverity.ERROR, summary: 'Failed to save messages', detail: `Server responded with [${status}]. Check the console for more information (F12)`, life: 3000});
            return false;
        }
    }

    async creditLead(agentEmail: string, retryLogin = true): Promise<boolean> {
        await this.refreshLogin();

        try {
            await axios.post('api/users/credit', {agentEmail});
            this.$toast.add({severity: ToastSeverity.SUCCESS, summary: 'Saved!', detail:'Lead credited successfully.', life: 3000});
            this.refreshData();
            return true;
        }
        catch (error: any) {
            let status = error.response?.status;
            console.log('Credit lead failed', error, this);
            if (status == 401) {
                this.user = null;
                let success = await this.refreshLogin();
                if (success && retryLogin) {
                    return this.creditLead(agentEmail, false);
                }
            }

            this.$toast.add({severity: ToastSeverity.ERROR, summary: 'Failed to credit lead', detail: `Server responded with [${status}]. Check the console for more information (F12)`, life: 3000});
            return false;
        }
    }

    async deleteAgent(agent: AgentClaimData, retryLogin = true): Promise<boolean> {
        await this.refreshLogin();

        try {
            await axios.delete('api/agents', {
                params: {
                    email: agent.agentEmail
                }
            });
            let index = this.agentClaimsData.indexOf(agent);
            if (index >= 0) this.agentClaimsData.splice(index, 1);
            this.$toast.add({severity: ToastSeverity.SUCCESS, summary: 'Agent Removed!', detail:'Removal successful.', life: 3000});
            return true;
        }
        catch (error: any) {
            let status = error.response?.status;
            console.log('Delete agent failed', error, this);
            if (status == 401) {
                this.user = null;
                let success = await this.refreshLogin();
                if (success && retryLogin) {
                    return this.deleteAgent(agent, false);
                }
            }

            this.$toast.add({severity: ToastSeverity.ERROR, summary: 'Failed to remove agent', detail: `Server responded with [${status}]. Check the console for more information (F12)`, life: 3000});
            return false;
        }
    }

    async saveChannelData(retryLogin = true): Promise<boolean> {
        await this.refreshLogin();

        try {
            await axios.patch('api/channels', this.channels);
            this.$toast.add({severity: ToastSeverity.SUCCESS, summary: 'Saved!', detail:'Update successful.', life: 3000});
            return true;
        }
        catch (error: any) {
            let status = error.response?.status;
            console.log('Save channels failed', error, this);
            if (status == 401) {
                this.user = null;
                let success = await this.refreshLogin();
                if (success && retryLogin) {
                    return this.saveChannelData(false);
                }
            }

            return false;
        }
    }

    async refreshLogin() {
        if (this.user == null) {
            try {
                let res = await axios.get('api/self');
                let user = res.data;
                let email = user?.email?.trim();
                if (email && email.length > 0) {
                    (this.$root as RootVue).user = user;
                }
                else {
                    this.$router.push('/');
                }
            }
            catch (error) {
                console.log('Refresh failed (user)', error);
                this.$router.push('/');
            }
        }

        return this.user != null;
    }

    async getAgentClaimsInTenant(): Promise<any> {
        let res = await axios.get('api/agents');
        return res.data as any;
    }

    async getUsersInTenant(): Promise<User[]> {
        let res = await axios.get('api/users');
        return res.data as User[];
    }

    async getTenants(): Promise<Tenant[]> {
        let res = await axios.get('api/tenants');
        return res.data as Tenant[];
    }

    async getOwnTenant(): Promise<Tenant> {
        let res = await axios.get('api/tenant');
        return res.data as Tenant;
    }

    async getMessages(): Promise<Message[]> {
        let res = await axios.get('api/messages');
        return res.data as Message[];
    }

    async getChannels(): Promise<ChannelSettings[]> {
        let res = await axios.get('api/channels');
        return res.data as ChannelSettings[];
    }
}
