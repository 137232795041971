import Vue from 'vue'
import RootVue from './RootVue'
import PrimeVue from 'primevue/config';

import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import '@/assets/css/theme_overrides.css';
import '@/assets/css/gradients.css';

import ToastService from 'primevue/toastservice';
import Ripple from 'primevue/ripple';

Vue.config.productionTip = false;
Vue.use(PrimeVue, { ripple: true });
Vue.directive('ripple', Ripple);
Vue.use(ToastService);

new RootVue().$mount('#app')
