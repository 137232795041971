







import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Toast from 'primevue/toast';

@Component({
    components: {
        Toast,
    }
})
export default class App extends Vue {
}
